import React from "react"
import Header from "@/components/header"
import Footer from "@/components/footer"

const Layout = ({ location, title, children }) => {
  // const rootPath = `${__PATH_PREFIX__}/`
  // const isRootPath = location.pathname === rootPath

  return (
    <>
      <div className="global-wrapper">
        <Header title={title} />
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

export default Layout
