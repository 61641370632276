import React from "react"

const Header = () => {
  return (
    <footer className="footer">
      {/* <p>
          <a href="/">Refactor Community</a> - for everyone.
        </p> */}
      {/* <p>
          Made with{" "}
          <span role="img" aria-label="Heart">
            ❤️
          </span>{" "}
          &amp;{" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.gatsbyjs.com/"
          >
            Gatsby
          </a>
          .
        </p> */}
      <small>Refactor Community © {new Date().getFullYear()}</small>
    </footer>
  )
}

export default Header
