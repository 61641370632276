import React from "react"
import { Link } from "gatsby"

const Header = ({ title }) => {
  return (
    <header className="header">
      <h1 className="header__heading">
        <Link to="/">
          <img
            className="header__img"
            alt="lof"
            src="/images/tipping-hand.png"
          />
          {title}
        </Link>
      </h1>
    </header>
  )
}

export default Header
